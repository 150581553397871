/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import "~leaflet/dist/leaflet.css";

.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.loading-background[_ngcontent-hsf-c185] {
    background-color: rgba(0, 0, 0, 0.2);
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #E98B11 !important;
    color: #fff !important;
    font-size: 1em !important;
  }
  /* Cible les actions de SweetAlert2 et inverse l'ordre des boutons */
.swal2-container .swal2-actions {
    display: flex;
    flex-direction: row-reverse !important; /* Inverse l'ordre des boutons */
    justify-content: center !important; /* Aligne les boutons au centre */
    margin: 1.25em auto 0 !important;
    padding: 0;
}

/* Si vous avez besoin de cibler des boutons spécifiques */
.swal2-container .swal2-confirm {
    order: 1 !important; /* Assure que le bouton "OUI" soit à gauche */
}

.swal2-container .swal2-cancel {
    order: 2 !important; /* Assure que le bouton "NON" soit à droite */
}
